<template>
  <video controls
    ref="videoPlayer" class="video-js video-player"/>
</template>

<script>
import videojs from 'video.js';

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/settings/_video_player.scss';
</style>
